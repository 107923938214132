<template>
  <div>
    <LineGraph
      v-if="showChart"
      :chartdata="chartData"
      :options="options"
      :styles="{
        height: '200px',
        position: 'relative',
      }"
    />
  </div>
</template>

<script>
import tagProvider from '@/providers/tag'

const LineGraph = () => import('@/components/UI/Charts/Line')

export default {
  components: {
    LineGraph,
  },

  props: {
    tagId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      dataLoaded: false,
      noData: false,
      data: null,
      isLoading: false,
      showChart: false,

      isSearching: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      nrOfMonths: 24,

      chartData: {
        labels: [],
        datasets: [],
      },

      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },

  computed: {},

  created() {
    this.getTagUsagePerMonth()
  },

  methods: {
    getTagUsagePerMonth() {
      let self = this

      if (!self.isSearching) {
        self.isSearching = true

        tagProvider.methods
          .getTagUsagePerMonth(
            this.tagId,
            this.year,
            this.month,
            this.nrOfMonths
          )
          .then((response) => {
            if (response.status === 200) {
              this.data = response.data

              let labels = []
              let dataset = []

              for (let i = 0; i < this.data.length; i++) {
                let d = this.data[i]

                labels.push(
                  self.$options.filters.getMonthName(d.Month, 'en', true) +
                    ' ' +
                    d.Year
                )
                dataset.push(d.TimesUsed)
                //dataset2.push(d.NrOfReservationCreated)
              }

              this.chartData.labels = labels
              this.chartData.datasets.push({
                label: 'Times used',
                type: 'line',
                fill: false,
                data: dataset,
                borderColor: '#2554FF',
                backgroundColor: '#2554FF',
                borderWidth: 1,
              })

              this.showChart = true
            }
          })
          .finally(() => {
            self.isSearching = false
          })
      }
    },
  },
}
</script>

<style></style>
